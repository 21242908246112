import Image from "next/image";
import React, { useState, useRef } from 'react';
import { Icon } from "@iconify/react";
import useLogin from "@/hooks/useLogin";
import FreeSamplePopup from '@/components-mobile/FreeSamplePopup';
import styles from './index.module.scss';

const ProductCard = ({ sourceData, typePage, collectChange, addChange, sampleChange, goDetailPage }) => {
  const { needLogin } = useLogin();
  const [getFreeSample, setGetFreeSample] = useState(false);
  const [collected, setCollected] = useState(sourceData.collect);
  const obtainRef = useRef(typeof window !== "undefined" ? JSON.parse(localStorage?.getItem("user"))?.obtain : false);
  const img = typePage === 'home' || typePage === 'collection'
    ? sourceData.alyImage ? sourceData.alyImage : sourceData.image
    : typePage === 'commission'
      ? sourceData.productImage
      : sourceData?.main_images?.[0]?.url;
  const finishRate = sourceData.finishRate ? sourceData?.finishRate + '%' : "-";
  const toggleCollect = (e) => {
    if (!needLogin) {
      setCollected(!collected);
    }
    collectChange(e, sourceData, collected);
  };
  const tipList = [
    {
      text: 'Top Selling',
      color: '#FF6C4F',
      bg: 'rgba(248, 240, 237, 0.94)',
      isShow: sourceData?.productTag2 === 1 ? true : false
    },
    {
      text: 'Trending',
      color: '#E04DB1',
      bg: 'rgba(248, 237, 247, 0.94)',
      isShow: sourceData?.productTag3 === 1 ? true : false
    },
    {
      text: 'Free Sample',
      color: '#1EC29A',
      bg: 'rgba(232, 241, 239, 0.94)',
      isShow: sourceData?.productTag1 === 1 ? true : false
    }
  ];
  return (
    <div className={styles.productCard} >
      <div className={styles.imageBox} onClick={() => { goDetailPage(sourceData); }}>
        <img className={`${styles.image} ${typePage === 'commission' && styles.imageCom}`} src={img} />
        <div className={styles.tipBox}>
          {tipList.map((item, index) => (<>
            {item.isShow && <div className={styles.tip} style={{ background: item.bg, color: item.color }} key={index}>{item.text}</div>}
          </>)
          )}
        </div>
        <div className={styles.collectBox}>
          <Icon icon='solar:star-bold-duotone'
            className={`${styles.collect} ${collected ? styles.collectActive : ''}`}
            onClick={(e) => { toggleCollect(e, sourceData); }} />
        </div>
        <div className={styles.mainInfo}>
          <div className={styles.title}>{sourceData.title}</div>
          <div className={styles.price}>{sourceData?.price ?? "-"}</div>
        </div>
      </div>
      <div className={styles.contentEarn}>
        <div className={styles.earnTitle}>${sourceData?.earnPer ?? "-"}</div>
        <div className={styles.earnText}>Earn per sale</div>
      </div>
      <div className={styles.earns}>
        <div className={styles.earnsWarp}>
          <div className={styles.earnsMin}>
            <span className={`${styles.earnNum} ${obtainRef.current && styles.earnNumDiff}`}>{finishRate}</span>
            {!obtainRef.current && <Icon icon='dashicons:yes-alt' className={styles.earnsMinIcon} />}
          </div>
          <div className={styles.earnsTip}>Earnings</div>
        </div>
        <Icon icon='quill:arrow-right' className={styles.earnGo} />
        <div className={styles.earnsWarp}>
          <div className={`${styles.earnsMax} ${obtainRef.current && styles.earnsMaxDiff}`}>
            <span className={styles.earnNum}>{`${sourceData?.capRate}%` ?? "-"}</span>
            {obtainRef.current
              ? <Icon icon='dashicons:yes-alt' className={styles.earnsMinIcon} />
              : <Icon icon='mdi:lock' className={styles.earnsMaxIcon} />}
          </div>
          <div className={styles.earnsTip}>Max Earnings</div>
        </div>
      </div>
      <div className={styles.button}>
        <div className={styles.sample} onClick={(e) => {
          e.stopPropagation();
          setGetFreeSample(true);
        }}>
          <Image width={16} height={16} className={"w-4 h-4 mr-1"} src={"/images/free-sample.svg"} alt={""}></Image>Sample</div>
        <div className={styles.add} onClick={(e) => { addChange(e, sourceData); }}>
          <Icon icon='mdi:lock' className={styles.earnsMaxIcon} />Add</div>
      </div>
      <FreeSamplePopup isVisible={getFreeSample} sourceData={sourceData} obtainRef={obtainRef.current} closePopup={() => setGetFreeSample(false)} title={'Get Sample'} />
    </div>
  );
};
export default ProductCard;