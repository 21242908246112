
import { Icon } from "@iconify/react";
import styles from './index.module.scss';

const Footer = () => {
  const contactList = [
    {
      icon: 'cib:gmail',
      text: 'E-Mail',
      bg: '#4C6EF3',
      size: '14px',
      color: '#fff'
    },
    {
      icon: 'logos:tiktok-icon',
      text: 'TikTok',
      bg: '#EDF0FE',
      size: '14px'
    },
    {
      icon: 'logos:whatsapp-icon',
      text: 'WhatsApp Business',
      bg: 'transparent',
      size: '26px'
    },
    {
      icon: 'bxl:whatsapp',
      text: 'WhatsApp Channel',
      bg: '#60D669',
      size: '20px',
      color: '#fff'
    }
  ];
  const QRList = [
    {
      src: '/static/QR1.png',
      text: 'Follow Us TikTok'
    },
    {
      src: '/static/QR2.png',
      text: 'Contact Us WhatsApp Business'
    },
    {
      src: '/static/QR3.png',
      text: 'Focus Us WhatsApp Channel'
    }
  ];
  const info = [
    {
      text: 'abComo ecommerce pte ltd.2021'
    },
    {
      text: 'Privacy Policy'
    },
    {
      text: 'All rights reserved'
    },
    {
      text: 'Terms and Conditions'
    }
  ];
  return (
    <div className={styles.homeFooter}>
      <img className={styles.logo} alt='logo' src={"https://anystarr-image.oss-ap-southeast-1.aliyuncs.com/anystarr-next-asset/anystarr_icon.png"} />
      <div className={styles.title}>Contact Us</div>
      <div className={styles.contactGroup}>
        {contactList.map((item, index) => (
          <div key={index} className={styles.contactBox}>
            <div style={{ background: item.bg, color: item.color }} className={styles.iconBox}>
              <Icon icon={item.icon} style={{ fontSize: item.size }} className={styles.docuIcon} />
            </div>
            <div className={styles.contactText}>{item.text}</div>
          </div>
        ))}
      </div>
      <div className={styles.QRGroup}>
        {QRList.map((item, index) => (
          <div className={styles.QRBox} key={index}>
            <img className={styles.QR} src={item.src} />
            <div className={styles.text}>{item.text}</div>
          </div>
        ))}
      </div>
      <div className={styles.footerInfo}>
        {info.map((item, index) => (
          <div className={`${styles.text} ${index === 1 && styles.textDiff}`} key={index}>{item.text}</div>
        ))}
      </div>
    </div>
  );
};
export default Footer;