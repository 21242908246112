export const countryPhoneCodes = [
  { label: "🇨🇳 China", value: "86" },
  { label: "🇺🇸 USA", value: "1" },
  { label: "🇯🇵 Japan", value: "81" },
  { label: "🇬🇧 UK", value: "44" },
  { label: "🇩🇪 Germany", value: "49" },
  { label: "🇫🇷 France", value: "33" },
  { label: "🇮🇳 India", value: "91" },
  { label: "🇨🇦 Canada", value: "1" },
  { label: "🇦🇺 Australia", value: "61" },
  { label: "🇷🇺 Russia", value: "7" },
  { label: "🇧🇷 Brazil", value: "55" },
  { label: "🇰🇷 South Korea", value: "82" },
  { label: "🇮🇹 Italy", value: "39" },
  { label: "🇪🇸 Spain", value: "34" },
  { label: "🇲🇽 Mexico", value: "52" },
  { label: "🇿🇦 South Africa", value: "27" },
  { label: "🇮🇩 Indonesia", value: "62" },
  { label: "🇸🇬 Singapore", value: "65" },
  { label: "🇹🇭 Thailand", value: "66" },
  { label: "🇹🇷 Turkey", value: "90" },
  { label: "🇳🇱 Netherlands", value: "31" },
  { label: "🇸🇪 Sweden", value: "46" },
  { label: "🇨🇭 Switzerland", value: "41" },
  { label: "🇦🇪 UAE", value: "971" },
  { label: "🇲🇾 Malaysia", value: "60" },
  { label: "🇦🇷 Argentina", value: "54" },
  { label: "🇸🇦 Saudi Arabia", value: "966" },
  { label: "🇳🇬 Nigeria", value: "234" },
  { label: "🇳🇴 Norway", value: "47" },
  { label: "🇵🇭 Philippines", value: "63" },
  { label: "🇻🇳 Vietnam", value: "84" },
  { label: "🇨🇿 Czech Republic", value: "420" },
  { label: "🇵🇱 Poland", value: "48" },
  { label: "🇮🇪 Ireland", value: "353" },
  { label: "🇳🇿 New Zealand", value: "64" },
];