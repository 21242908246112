import { Modal } from "antd";
import {FailIcon, readyIcon} from "@/config/config";


const EligibleModal = ({ onCancel, show }) => {

  return <Modal title={null} centered footer={null} width={530} visible={show} onCancel={(e) => {
    e.stopPropagation();
    onCancel();
  }}>
    <div className={"flex flex-col items-center mt-5"}>
      <img src={readyIcon} className={"w-[58px] h-[58px] object-cover mb-4"} />
      <div className={"flex flex-col"}>
        <div className={"flex"}>
          <div className={"h-[21px] flex flex-col justify-center mr-2"}>
            <div className={"w-1 h-1  rounded-full bg-[#222] shrink-0"}></div>
          </div>
          <div className={"text_222 text-[14px] leading-[21px] break-words"}>
            Ready to join our Creator Affiliate Program? After joining, you’ll enjoy our
            <span className={"high_light mx-1 font-bold"}>highest commissions</span>
            on every product (ranging from 12%-30%),
            <span className={"high_light mx-1 font-bold"}>exclusive bonuses</span>,
            personalized
            <span className={"high_light mx-1 font-bold"}>1-on-1 support</span>, more free samples, and TikTok flash sales.
          </div>
        </div>

        <div className={"flex"}>
          <div className={"h-[21px] flex flex-col justify-center mr-2"}>
            <div className={"w-1 h-1  rounded-full bg-[#222] shrink-0"}></div>
          </div>
          <div className={"text_222 text-[14px] leading-[21px]"}>
            We'll send you a <span className={"high_light mx-1 font-bold"}>partner link</span> to your TikTok within 24 hours, please open TikTok to check it and click the <span className={"high_light mx-1 font-bold"}>accept request</span>!
          </div>
        </div>
        <div className={"flex"}>
          <div className={"h-[21px] flex flex-col justify-center mr-2"}>
            <div className={"w-1 h-1  rounded-full bg-[#222] shrink-0"}></div>
          </div>
          Welcome to join us!
        </div>
      </div>
      <div onClick={onCancel} className={"h-[33px] hover:opacity-80 transition-all ease-in w-[200px] flex items-center justify-center cursor-pointer bg-linear text-white text-[14px] font-bold rounded mt-5"}>Get it</div>
    </div>
  </Modal>;
};


export const Eligible7DTipsModal = ({ onCancel, show, content }) =>{

  return <Modal title={null} centered footer={null} width={350} visible={show} onCancel={(e) => {
    e.stopPropagation();
    onCancel();
  }}>
    <div className={"flex flex-col items-center mt-5"}>
      <img src={FailIcon} className={"w-[58px] h-[58px] object-cover mb-4"} />
      <div className={"text_222 text-center text-[14px] leading-[21px]"}>
        {
          content
        }
      </div>
    </div>
  </Modal>;
};

export default EligibleModal;
