import { Icon } from "@iconify/react";
import { LoadingGIF } from "@/config/config";
import styles from './index.module.scss';

const Modal = ({ type, outLogin, children, cancel, footerBotton }) => {
  console.log('~333333', children, !children);
  return <div className={styles.modal}>
    <div className={styles.content}>
      <div className={styles.cancel}>
        <Icon icon='line-md:close' className={styles.cancelIcon} onClick={(e) => { e.stopPropagation(); cancel(); }} />
      </div>
      {type === 'loading' && <img src={LoadingGIF} className={styles.loadingIcon} />}
      {!type && <Icon icon='solar:info-circle-bold-duotone' className={styles.tipIcon} />}
      <div className={styles.text}>
        {children}
      </div>
      {footerBotton && <div className={styles.wrap}>
        <div className={styles.leftBotton} onClick={outLogin}>Yes</div>
        <div className={styles.rightBotton} onClick={(e) => { e.stopPropagation(); cancel(); }}>No</div>
      </div>}
    </div>
  </div >;
};
export default Modal;