import { Icon } from "@iconify/react";
import styles from './index.module.scss';

const SearchBar = ({ productListParams, onPress }) => {
  const { searchType, searchName } = productListParams;
  return (
    <div className={styles.searchBox} onClick={onPress}>
      {searchType && searchName ?
        <div className={styles.infoSearch}>
          <div className={styles.infoSearchLeft}>
            <span className={styles.searchType}>{searchType === 2 ? 'Product Name' : 'Product Link'}</span>
            <span className={styles.searchTitle}>{searchName}</span>
          </div>
          <Icon icon='mingcute:search-line' className={styles.searchIcon} />
        </div>
        :
        <div className={styles.noSearch}>
          <div className={styles.searchText}>
            Search Product Name、Product Link...
          </div>
          <Icon icon='mingcute:search-line' className={styles.searchIcon} />
        </div>}
    </div>
  );
};
export default SearchBar;