import Image from "next/image";
import { Button, Form, Input, Select } from "antd";
import { useEffect, useState } from 'react';
import gaTracker from "@/ga";
import { Icon } from "@iconify/react";
import Modal from '@/components-mobile/Modal';
import { countryPhoneCodes } from '@/constants/Phone';
import EligibleModal from "@/components/EligibleModal";
import { APICapInvitation, APIRefreshCapStatus, APIRequestSampleCreate } from '@/api';
import styles from './index.module.scss';


const FreeSamplePopup = ({ isVisible, title, sourceData, obtainRef, closePopup }) => {
  const [form] = Form.useForm();
  const [code, setcode] = useState();
  const [userInfoLocal, setUserInfoLocal] = useState(obtainRef ? obtainRef : false);
  const [noEligible, setNoEligible] = useState(false);  // 未签约提示
  const [eligibleModal, setEligibleModal] = useState(false);
  const [personInfo, setPersonInfo] = useState(false);
  const [eligible7dayModal, setEligible7dayModal] = useState(false);

  useEffect(() => {
    if (isVisible) {
      // 禁止页面滚动
      document.body.style.overflow = 'hidden';
    } else {
      // 恢复页面滚动
      document.body.style.overflow = '';
    }
    // 在组件卸载时恢复页面滚动
    return () => {
      document.body.style.overflow = '';
    };
  }, [isVisible]);

  const getCapInvite = () => {
    // gaTracker.trackEvent("profile_obtain");
    APICapInvitation()
      .then((resp) => {
        if (resp.code === '1' && resp?.data?.result?.isSucess) {
          setEligibleModal(true);
        } else if (!resp?.data?.result?.isSucess) {
          setEligible7dayModal(true);
        }
        setcode(resp?.data?.result?.code);
      }).catch((e) => { console.log(e); });
  };
  const reEligible = () => {
    // gaTracker.trackEvent("profile_already");
    APIRefreshCapStatus()
      .then((resp) => {
        if (resp?.data?.result) {
          setUserInfoLocal(true);
        } else {
          setNoEligible(true);
          setUserInfoLocal(false);
        }
      });
  };
  const getSample = () => {
    setPersonInfo(true);
  };
  const clearInfo = () => {
    form.resetFields();
  };
  const handleSubmit = async () => {
    const values = await form.validateFields(); // 触发校验
    console.log('~222', values);
    APIRequestSampleCreate(JSON.stringify(values)).then((resp) => {
      console.log("~resp", resp);
    });

    // form.resetFields();
  };
  // 校验函数
  const validate = () => {
    const values = form.getFieldsValue();
    console.log("表单数据:", values);
    let isValid = true;
    const newErrors = { name: "", email: "" };

    if (!values.name.trim()) {
      newErrors.name = "请输入姓名";
      isValid = false;
    }
    if (!values.email?.includes("@")) {
      newErrors.email = "请输入有效的邮箱";
      isValid = false;
    }

    // setErrors(newErrors);
    return isValid;
  };

  const prefixSelector = (
    <Form.Item name={"prefix"} noStyle>
      <Select
        style={{
          width: 70,
        }}
        defaultValue={"86"}
        getPopupContainer={(triggerNode) => triggerNode.parentNode} // 解决被遮挡问题
      >
        {countryPhoneCodes.map((item, index) => (
          <Select.Option key={index} value={item.value}>
            +{item.value}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );

  return (
    isVisible && <div className={styles.overlay}
      onClick={(e) => {
        e.preventDefault();
        closePopup();
      }}
      onTouchMove={(e) => {
        e.preventDefault(); // 阻止默认的触摸移动行为，防止页面滚动
      }}>
      <div className={`${styles.popup} ${isVisible ? styles['popup-enter'] : styles['popup-exit']
        }`} onClick={(e) => e.stopPropagation()} // 阻止事件冒泡，避免点击内容关闭 Popup
      >
        <div className={styles.header}>
          <div className={styles.headerLeft}>{title}</div>
          <div className={styles.headerRight} onClick={() => { closePopup(); }}>
            <Icon icon='line-md:close' className={styles.headerRightIcon} />
          </div>
        </div>
        <div className={styles.content} onTouchMove={(e) => {
          e.preventDefault(); // 阻止默认的触摸移动行为，防止页面滚动
        }}>
          <div className={styles.productBox}>
            <div className={`${styles.imageBox}`}>
              <img className={`${styles.image} `} src={sourceData?.alyImage} />
            </div>
            <div className={styles.title}>{sourceData?.title}</div>
          </div>
          <div className={styles.info}>
            {personInfo ?
              <div className={styles.infoTitle}>
                Fill in the information for offline sampling
              </div>
              : <div className={styles.infoTitle}>
                Join our Creator Affiliate Program to get offline samples!
              </div>}

            {!userInfoLocal ? (
              personInfo ?
                <Form
                  // name={"Free Sample Info"}
                  // onFinish={() => { }}
                  form={form}
                  autoComplete={"off"}
                  layout={"vertical"}
                  style={{ marginTop: '12px', marginBottom: '42px' }}
                >
                  <Form.Item
                    label={"Address"}
                    name={"address"}
                    // className={"flex-1"}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your address!',
                      },
                    ]}
                  >
                    <Input maxLength={500} placeholder={"An available address to receive the sample"} />
                  </Form.Item>
                  <Form.Item
                    label={"Zip Code"}
                    name={"zipCode"}
                    // className={"flex-1"}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your Zip Code!',
                      },
                    ]}
                  >
                    <Input maxLength={20} placeholder={"Zip code"} />
                  </Form.Item>
                  <Form.Item
                    label={"E- Mail"}
                    name={"email"}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your E- Mail!',
                      },
                    ]}
                  >
                    <Input maxLength={300} placeholder={"Your E-Mail address"} />
                  </Form.Item>
                  <Form.Item
                    label={"Name"}
                    name={"name"}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your name!',
                      },
                    ]}
                  >
                    <Input maxLength={50} placeholder={"Your name"} />
                  </Form.Item>
                  <Form.Item
                    // form={form}
                    label={"Phone"}
                    name={"phone"}
                    rules={[
                      {
                        required: true,
                        message: 'Please input your phone number!',
                      },
                    ]}
                  >
                    <Input
                      maxLength={20}
                      addonBefore={prefixSelector}
                      placeholder={"The contact phone number"} />
                  </Form.Item>
                  {/* <div className={"flex-1"}>

                  </div> */}
                  {/* <div className={"flex items-center gap-4"}> */}
                  <Form.Item
                    label={"Product Note"}
                    name={"productNote"}
                  >
                    <Input.TextArea count={{
                      show: true,
                      max: 500,
                    }}
                      maxLength={500}
                      placeholder={"Please enter the size, color and other information. Leave blank to automatically select the default option."} />
                  </Form.Item>
                  {/* </div> */}
                </Form>
                : <div style={{ paddingBottom: '20px' }}>
                  <ol>
                    <li className={styles.infoText} style={{ marginTop: '17px', marginLeft: '15px' }}>Click <span className={styles.infoTextDiff}>Add to Showcase</span> to add the product to your showcase</li>
                    <li className={styles.infoText} style={{ marginLeft: '15px' }}>Check the TikTok Product Card Info to see if there is a <span className={styles.infoTextDiff}>Get Free Sample</span> button</li>
                    <li className={styles.infoText} style={{ marginLeft: '15px' }}>If you see the Get Free Sample button on TikTok, <span className={styles.infoTextDiff}>follow the process below</span> to request a sample</li>
                    <li className={styles.infoText} style={{ marginLeft: '15px' }}>If you don't see the Get Free Sample button on TikTok, click the <span className={styles.infoTextDiff}>Request Sample Offline</span> button below to send an offline request sample</li>
                  </ol>
                  <Image width={324} height={120} src={"/images/product/get-free-sample-icon.svg"} alt={""}></Image>
                </div>
            )
              : <div>
                <ol>
                  <li className={styles.infoText} style={{ marginTop: '17px', marginLeft: '15px' }}>Ready to join our Creator Affiliate Program? After joining, you’ll enjoy:
                    <ul>
                      <li className={styles.infoText} >Our <span className={styles.infoTextDiff}>Highest Commissions</span> on every product (ranging from 12%-30%)</li>
                      <li><span className={styles.infoTextDiff}>Offline samples</span></li>
                      <li><span className={styles.infoTextDiff}>Exclusive Extra View Boost</span></li>
                      <li className={styles.infoText}>Personalized <span className={styles.infoTextDiff}>1v1 support</span></li>
                      <li className={styles.infoText}>TikTok Flash Sales</li>
                    </ul>
                  </li>
                  <li className={styles.infoText} style={{ marginTop: '10px', marginLeft: '15px' }}>Click the “<span className={styles.infoTextDiff}>Obtain Eligibility</span>” button below and we'll send you a <span className={styles.infoTextDiff}>partner link</span> and more partner details from our affiliate account <span className={styles.infoTextDiff}>Uango</span> to your TikTok. Simply check the link we sent and <span className={styles.infoTextDiff}>accept the request </span>! </li>
                </ol>
              </div>
            }
          </div>
        </div>
        <div className={styles.footer}>
          {!userInfoLocal
            ? (personInfo
              ? <div className={styles.footerBox}>
                <div className={styles.submit} onClick={handleSubmit}>Submit</div>
                <div className={styles.cancel} onClick={clearInfo}>Clear All</div>
              </div>
              : <div className={styles.request} onClick={getSample}>Request Sample Offline</div>)
            : <div className={styles.footerBox}>
              <div className={styles.submit} onClick={getCapInvite}>Obtain Eligibility</div>
              <div className={styles.cancel} onClick={reEligible}>I'm already eligible</div>
            </div>}

        </div>
      </div>
      <EligibleModal show={eligibleModal} onCancel={() => setEligibleModal(false)}></EligibleModal>
      {
        eligible7dayModal && <Modal
          cancel={() => setEligible7dayModal(false)}
        >
          {code === 16004004 && <div>Sorry, you are not a TikTok shop creator and have no permission to bind.</div>}
          {code === 16004005 && <div>Sorry, you already joined TikTok affiliate's Creator Affiliate Program. If you already joined us, please click the<span className={styles.tipTextDiff}> I'm already eligible</span> button to update your data. If you joined another organization, please<span className={styles.tipTextDiff}> cancel your contract</span> and join us again.</div>}
          {code === 16004006 && <div>We've sent you a <span className={styles.tipTextDiff}>partner link </span>and more detailed partnership details from an account in the name of Uango to your TikTok, please check it and click the <span className={styles.tipTextDiff}>accept request</span>!</div>}
          {code === 16004014 && <div>Sorry, you can't join our Creator Affiliate Program because you have a TikTok shop.</div>}
          {code === 16007119 && <div>Sorry, we are currently only open for the <span className={styles.tipTextDiff}>US market,</span> your account region does not match the market!</div>}
          {code !== 16004004
            && code !== 16004005
            && code !== 16004006
            && code !== 16004014
            && code !== 16007119
            && code !== 0
            && <div>Sorry, an error of unknown origin has occurred, please wait for our staff to contact you.</div>}
        </Modal>
      }
      {
        noEligible && <Modal cancel={() => setNoEligible(false)}>
          <div>Join our <span className={styles.tipTextDiff}>Creator Affiliate Program</span> to enjoy the highest commission rate, click the <span className={styles.tipTextDiff}>Obtain Eligibility</span> button to join us!</div>
        </Modal>
      }
    </div >
  );
};
export default FreeSamplePopup;