
import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';

const SortInfo = ({ resetSort, getSortData, defaultSort, onDataChange, onReset }) => {
  const [selectedSortValue, setSelectedSortValue] = useState(defaultSort); // 存储Rate选中值
  const sortList = [{
    value: 1,
    label: 'Highest Total Sold',
  },
  {
    value: 3,
    label: 'Highest Sold Yesterday',
  },
  {
    value: 6,
    label: 'Highest Commission',
  },
  {
    value: 4,
    label: 'Highest Commission Rate',
  },
  {
    value: 2,
    label: 'Highest GMV',
  },
  {
    value: 7,
    label: 'Highest Rating',
  }];
  // 选择Sale
  const selectSort = (value) => {
    setSelectedSortValue(value); // 更新选中值
  };
  const clearSelected = () => {
    setSelectedSortValue(null);
  };

  useEffect(() => {
    if (resetSort) {
      clearSelected();
      onReset();
    }
    if (getSortData) {
      onDataChange(selectedSortValue);
    }
  }, [resetSort, getSortData]);

  return (
    <div className={styles.sortInfo}>
      {sortList.map((item, index) => (
        <div
          className={`${styles.sortBox} ${selectedSortValue === item.value ? styles.active : ''}`}
          key={index}
          onClick={() => selectSort(selectedSortValue === item.value ? null : item.value)}
        >
          {item.label}
        </div>
      ))}
    </div>
  );
};
export default SortInfo;